import "../assets/scss/components/msg.scss";

function MsgFail({title= 'Your payment on invoice was fail', description = "You'll redirect back after seconds"}) {
  return (
    <>
      <div className="big-loader">
        <div className="phone-number-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="custom-box-shadow phone-number-container text-center">
                <img alt="logo" src="./images/fail.svg" />
                  <p className="msgtitle">{title}</p>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MsgFail;
