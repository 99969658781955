import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/scss/components/store.scss";
import { Navigate } from "react-router-dom";
function StoreInfo() {
  let getuserdata = JSON.parse(sessionStorage.getItem("user"));
  let productsdata = JSON.parse(sessionStorage.getItem("getCart"));
  console.log(productsdata);
  return (
    <React.Fragment>
      {!productsdata ? <Navigate to="/" replace={true} /> : ""}
      <div className="text-center ">
        <img alt="logo" src="./images/Logo.svg" />
        <div className="row text-center">
          <img className="merchant-logo" alt="merchant-logo" src={productsdata.cart.merchant.logo_path} />
        </div>
        <p className="store-wrap">
          Welcome,{" "}
          <a className="store-name" href="#">
            {productsdata.creator}
          </a>
        </p>
      </div>
      <div className="text-center row container d-flex gy-4 mw-lg-60">
          <div className="col-12">
            <div className="available-balance">
              <p className="available-amount">
                {getuserdata.balance.available_balance} SAR
              </p>
              <p className="available-text"> Available balance </p>
            </div>
          </div>
          <div className="col-12">
            <div className="available-balance text-left">
              <p className="available-text"> Invoice Payment Term</p>
              <span className="light-text">
                {sessionStorage.getItem("expires_in")}{" "} Day(s)
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="available-balance text-left">
            <p className="available-text"> Credit Limit </p>
              <span className="light-text">
                {getuserdata.balance.credit_limit||'0.00'} SAR
              </span>
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="available-balance text-left">
              <p className="available-text">Used balance </p>
              <span className="light-text">
                {getuserdata.balance.used_balance} SAR
              </span>
            </div>
          </div>



      </div>
    </React.Fragment>
  );
}
export default StoreInfo;
