import React from "react";
import Loader from "./_elements/loader";
import "../assets/scss/components/loading.scss";
import axios from "axios";
function Loadingrequest() {
  let saveUrl = process.env.REACT_APP_API_URL;

  var config = {
    method: "post",
    url:
      saveUrl +
      "transactions/pay/moneyHash/" +
      sessionStorage.getItem("transaction_id"),
   
  };
  axios(config)
    .then(function (response) {
      if (response.data.message === "success") {
        if (typeof window !== "undefined") {

          window.location.replace(response.data.data.embed_url);
        }
      } else {
        if (typeof window !== "undefined") {
          window.location.replace("/fail");
        }
      }
    })
    .catch(function (error) {});

  return (
    <>
      <div className="phone-number-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="custom-box-shadow phone-number-container p-relative text-center">
                <img alt="logo" src="./images/Logo.svg" />
                <p className="process-request">Processing Your Request..</p>
                <Loader />
                <p className="Proceeing">Processing..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loadingrequest;
