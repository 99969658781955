import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Favicon from "../_elements/fav";
import Rate from "../_elements/rate";
import Buynow from "../_elements/buy-now";
import { Rating } from 'react-simple-star-rating'


function ProductDetail(data) {

  const [saveProducts,setsaveProducts] = useState();
  const [rating, setRating] = useState(0)

 useEffect(()=>{
  setsaveProducts(data?.props?.cart?.products[0]);
  setRating(data?.props?.rate_average*20);
 
 })
  return (
    <>
      <div className="col-12 col-lg-6 order-2 order-lg-0">
        <div className="product-information">
          <div className="d-flex justify-content-between align-items-center mb-10">
            <h3 className="product-title">
              {saveProducts?.title}
            </h3>
            <Favicon/>
          </div>
          <span className="old-price">$  {saveProducts?.old_price}</span>
          <span className="new-price">$ {saveProducts?.price}</span>
          <p className="creator mt-15 mb-10">Creator by Ehab Hafez</p>
          <div className="d-flex align-items-center flex-wrap">
          {data?.props?.questions_count? <><img
              alt="icon-comment"
              className="img-fluid"
              src="./images/icon-comment.svg"
            />
            <span className="answered-questions">{data.props.questions_count} answered questions</span></> :""}
            <li className="review-list">
            <Rating readonly={true} ratingValue={rating}  />
              {data.props.reviews_count?  <span className="review-number">{data.props.reviews_count} Reviews</span>:""}
            </li>
          </div>
        
        </div>
        <div className="buttons-wrapper d-flex flex-wrap">

          <Buynow/>
        </div>
      </div>
    </>
  );
}
export default ProductDetail;
