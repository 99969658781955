import GetCart from "../../components/get-cart";


function App() {
  return (
    <section className="product-hero-section">
<div className="container">
    <div className="row">
      <GetCart/>
    </div>
</div>
</section>
  );
}

export default App;
