import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function Slidersection(imgs) {
  var settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <React.Fragment>
      <Slider {...settings}>
        {imgs?.props?.cart?.products[0].images.map((item, index) => {
          return (
            <div className="slick-item">
              <img alt="profile_img" className="img-fluid" src={item.photo} />
            </div>
          );
        })}
      </Slider>
    </React.Fragment>
  );
}
export default Slidersection;
