import React from "react";
import "../../assets/scss/elements/favoricon.scss"
function Favicon() {
    function isClicked(){
        document.querySelector('.heart-icon').classList.toggle("fav");
    }
    return (
    <>
    
    <button onClick={isClicked} className="heart-icon">
        
    
    	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.0074 3.94402L12.1049 4.97552L11.2023 4.07296C9.26828 2.13892 6.17381 1.62317 3.72402 3.17041C0.500611 5.36232 0.113802 9.74616 2.69253 12.4538L3.59508 13.3564L11.9759 21.7372L20.3568 13.3564L21.2594 12.4538C23.5802 10.133 23.5802 6.39381 21.2594 4.07296C19.0674 1.75211 15.3283 1.75211 13.0074 3.94402Z" stroke="#FF4D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</button>
    </>
  );
}
export default Favicon;
