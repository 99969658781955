import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/scss/components/order-detail.scss";
import Slidersection from "../slider";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import Bigloader from "../big-loader";
import MsgSuccess from "../MsgSuccess";
import MsgFail from "../MsgFail";

function Orderdetails() {

  let productsdata = JSON.parse(sessionStorage.getItem("getCart"));
  const [redirectUrl, setRedirectUrl] = useState('/loading');
  const [isvalid, setIsValid] = useState(false);
  const [savePayment, setSavePayment] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [msgFail, setMsgFail] = useState(false);
  const [confirmSelect, setConfirmSelect] = useState(false);


  var totalOldPrice = productsdata.cart.products.reduce((prev,next) => prev + parseFloat(next.total_old_price),0);
  var totalPrice    = productsdata.cart.products.reduce((prev,next) => prev + parseFloat(next.total),0);
  var shippingAmount    = (parseFloat(productsdata.cart.shipping_amount)||0);
  var vatAmount         = parseFloat(productsdata.cart.vat_amount);
  var discountAmount    = (parseFloat(productsdata.cart.discount)||0);
  var GrossOrderValue   = ((totalPrice + vatAmount + shippingAmount) - discountAmount).toFixed(1);

  var totalDiscount = productsdata.cart.products.reduce((prev,next) => prev + parseFloat(next.discount * next.discount),0);
  
  function selectpayment(params) {
    sessionStorage.setItem("selected_payment_method", params);
    setConfirmSelect(true);
  }

  let saveUrl = process.env.REACT_APP_API_URL;
  function Confirmorder() {

    setIsLoading(true);
      var config = {
        method: "post",
        url: saveUrl + "store-order/" + sessionStorage.getItem("id"),
        data: {
          payment_method: sessionStorage.getItem("selected_payment_method"),
      },
    };

    axios(config)
    .then(function (response) {
      if (response.data.status) {
          sessionStorage.setItem("order_id", response.data.data.order_id);
          sessionStorage.setItem("transaction_id", response.data.data.transaction_id);
          
          if(response.data.data.status_msg == true){setMsgSuccess(true);}
          else{setMsgFail(true);}
          
          setIsValid(true);
          setRedirectUrl(response.data.data.redirect_url);
        }
    });
    // .catch(function (error) {
    //   setMsgFail(true);
    // });

    setTimeout(function() {setIsLoading(false); }, 1000);

  }
  useEffect(() => {
    sessionStorage.getItem("payment_method")
      ? setSavePayment(JSON.parse(sessionStorage.getItem("payment_method")))
      : setSavePayment(false);
  }, []);
  return (
    <>
      {isLoading? ( <Bigloader /> ) : ""}
      {msgSuccess? ( <MsgSuccess /> ) : ""}
      {msgFail? ( <MsgFail /> ) : ""}
      {isvalid ?  setTimeout(function() {window.location.replace(redirectUrl); }, 1000) : ""}

      <div className="col-12 col-lg-10 offset-lg-1">
        <div className="order-detail-wrapper">
          <p className="order-name">Order Details</p>
          <div className="order-detail">
            {productsdata.cart.products.map((item, index) => {
              return (
                <div className="row mb-40">
                  <div className="col-lg-3 col-12">
                    <div className="slick-wrapper mb-40">
                      {/* <Slidersection props={productsdata} /> */}
                      <img alt="profile_img" className="img-fluid" src={item.photo_url} />

                    </div>
                  </div>
                  <div className={`${ item.discount > 0 ? 'col-lg-4' : 'col-lg-5'} col-12`}>
                    <p className="order-id">ID #{item.id}</p>
                    <p className="order-product-name">{item.title}</p>
                    <p className="quantity">Quantity : {item.quantity}</p>
                    { item.discount > 0 ? <p className="discount"> {item.discount} SAR Off</p> : ""}
                  </div>
                  { item.discount > 0 ? 
                  <div className="col-lg-3 offset-lg-2">
                    <span className="value"><del className="discount">{parseFloat(item.total_old_price).toFixed(2)}</del> {parseFloat(item.total).toFixed(2)} SAR </span>
                  </div>
                  :
                  <div className="col-lg-2 offset-lg-2">
                    <span className="value">{parseFloat(item.total).toFixed(2)} SAR </span>
                  </div>
                  }
                </div>
              );
            })}

            <div className="price-details-wrapper">
              <div className="d-flex align-items-center justify-content-between mb-20">
                <p className="price-detail">Sub Total</p>
                <p className="value"> {totalPrice.toFixed(2)} SAR</p>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-20">
                <p className="price-detail">Shipping</p>
                <p className="price-detail">
                  <strong>{shippingAmount} SAR</strong>
                </p>
              </div>
            
              <div className="d-flex align-items-center justify-content-between mb-20">
                <p className="price-detail">Discount</p>
                <p className="discount">
                  <strong>{discountAmount} SAR</strong>
                </p>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-20">
                <p className="price-detail">Vat</p>
                <p className="price-detail">
                  <span className="pr-20">{productsdata.cart.vat } %</span>{" "}
                  <strong>{vatAmount} SAR</strong>
                </p>
              </div>

              
            </div>
            <div className="total-price d-flex justify-content-between mt-20 align-items-center">
              <p className="total-price-text">Gross order value</p>
              <p className="total-price-amount">
                {GrossOrderValue} SAR
              </p>
            </div>
            <div className="payment-info">
              <p className="payment-method"> Payment Method :
              {confirmSelect? "" : ( <p className="ex-date text-danger" disabled> Select Payment Method</p> )}
              </p>
              {savePayment ? savePayment.map((item, index) => {
                    return (
                      <>
                        {" "}
                        <div className="payment d-flex align-items-center justify-content-between ">
                          <div className="payment-image payment-name">
                            {item.name ? (
                              <label
                                onClick={function () {
                                  selectpayment(item.id);
                                }}
                                className="radio-container"
                              >
                                {item.title}
                                <input
                                  type="radio"
                                  required
                                  onClick={function () {
                                    selectpayment(item.id);
                                  }}
                                  name="Payment"
                                />
                                <span className="checkmark"></span>
                              </label>
                            ) : (
                              ""
                            )}
                            {item.brief ? (
                              <span className="payment-desc">{item.brief}</span>
                            ) : (
                              ""
                            )}
                          </div>
                          {item.logo ? (
                            <div className="payment-image payment-name">
                              {/* <img alt="payment_Image" src={item.logo} /> */}
                              <img alt="payment_Image" src="./images/Logo.svg" />
                            </div>
                          ) : (
                            ""
                          )}
                          {item.description ? (
                            <p className="ex-date">{item.description}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
          {confirmSelect?
            (
              <button
                className="confirmation-bottom"
                onClick={function () {
                  Confirmorder();
                }} > Confirm </button>

            )  : "" }
        </div>
      </div>
    </>
  );
}
export default Orderdetails;
