import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StoreInfo from "../../components/checkout/store";
import Orderdetails from "../../components/checkout/order-detail";
function Checkout() {
  var settings = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <React.Fragment>
      <section className="balance-section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-12 col-lg-10">
              <div className="checkout-wrapper ">
                <StoreInfo/>
              <Orderdetails/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default Checkout;
