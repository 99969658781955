import React from "react";
import Loader from "./_elements/loader";
import "../assets/scss/components/loading.scss";

function Bigloader() {
  return (
    <>
      <div className="big-loader">
        <Loader />
      </div>
    </>
  );
}
export default Bigloader;
