import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/global.scss";
import RouterOutlet from "./router-outlet";
import Header from "./components/header";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
let saveAppKey = process.env.REACT_APP_app_key;

axios.interceptors.request.use((request) => {
  if (
    !request.url.includes("get-cart") &&
    !request.url.includes("auth/login") &&
    !request.url.includes("auth/verify")
  ) {
    request.headers["Authorization"] = sessionStorage.getItem("access_token");
  }
  
 
  request.headers.Accept = "application/json";
  request.headers.locale= "en";
  request.headers["app-key"] = saveAppKey;
  return request;
});
axios.interceptors.response.use((response)=>{


 
  return response;
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <Header />
      <ToastContainer />
      <RouterOutlet />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
