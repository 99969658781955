import "../assets/scss/components/filling-number.scss";
import ReactCodeInput from "react-code-input";
import { Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import axios from "axios";
import { getCartapi } from "../services/api";
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
  
function Fillingnumber() {
  const [isLoggedIn, setLoggedIn] = useState(true);
  const [validOtp, setvalidOtp] = useState(false);
  const [newUser, setnewUser] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(sessionStorage.getItem("country_code")+' '+sessionStorage.getItem("phone"));
  const [isvalid, setIsValid] = useState(false);
  const [isPinCodeValid, setIsPinCodeValid] = useState(0);
  const handlePinChange = (pinCode) => {
    setIsPinCodeValid(pinCode);
    if (isPinCodeValid.length > 2) {
      setvalidOtp(true);
    } else {
      setvalidOtp(false);
    }
  };
  let saveUrl = process.env.REACT_APP_API_URL;

  function confirmotp() {
    var config = {
      method: "post",
      url: saveUrl + "auth/verify",
      data: {
        phone: sessionStorage.getItem("phone"),
        country_code: sessionStorage.getItem("country_code"),
        otp_code: isPinCodeValid,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status) {
          sessionStorage.setItem(
            "access_token",
            "bearer " + response.data.data.access_token
          );
          
  
          sessionStorage.setItem("expires_in", response.data.data.expires_in);
          sessionStorage.setItem(
            "user",
            JSON.stringify(response.data.data.user)
          );

          response.data.data.new_customer
            ? setnewUser(true)
            : setnewUser(false);
          setIsValid(true);
        }
      })
      .catch(function (error) {
      
        toast(error.response.data.message);
      });
  }

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed && document.querySelector('.timer.d-none')) {
      
     document.querySelector('.timer.d-none').classList.remove('d-none')
      
      return <span>{minutes}:{seconds}</span>;
    } else {
      // Render a countdown
      return <span>{minutes}:{seconds}</span>;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("status") === "true") {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <>
      {!isLoggedIn ? <Navigate to="/sign-in" replace={true} /> : ""}
      {isvalid ? (
        newUser ? (
          <Navigate to="/Signinform" replace={true} />
        ) : (
          <Navigate to="/checkout" replace={true} />
        )
      ) : (
        ""
      )}
      <div className="otp-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="custom-box-shadow otp-container text-center">
                <img alt="logo" src="./images/Logo.svg" />
                <p className="enter-number">
                  We'll text your OTP code this number
                  <br /> {phoneNumber}{" "}
                </p>
                <ReactCodeInput
                  type="number"
                  fields={4}
                  isValid={validOtp}
                  onChange={handlePinChange}
                  width="20%"
                />

                <p className="timer">
                  Send another code{" "}
                  <strong>
                    <Countdown
                      renderer={renderer}
                      date={Date.now() + 58000}
                      
                    
                  
                      zeroPadTime={0}
                      daysInHours={true}
                    />
                  </strong>
                </p>
                {validOtp ? (
                  <button
                    className="confirm"
                    onClick={function () {
                      confirmotp();
                    }}
                  >
                    Confirm
                  </button>
                ) : (
                  <button disabled className="confirm">
                    Confirm
                  </button>
                )}
                <p className="timer d-none">
                  Didn't receive the code?{" "}
                  <button
                    className="resend-button"
                    onClick={function () {
                      getCartapi(
                        sessionStorage.getItem("country_code"),
                        sessionStorage.getItem("phone")
                      );
                    }}
                  >
                    Resend
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Fillingnumber;
