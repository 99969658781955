import "../assets/scss/components/signin-form.scss";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { useEffect, useState } from "react";
let saveUrl = process.env.REACT_APP_API_URL;

function Signinform() {
  const [value, setValue] = useState();
  const [isvalid, setIsValid] = useState(false);
  const [phoneNumber, setphoneNumber] = useState();
  const [emailAddress, setemailAddress] = useState();
  const [name, setName] = useState();
  const [crNumber, setcrNumber] = useState();

  useEffect(() => {
    if (value && isValidPhoneNumber(value)) {
      setphoneNumber(parsePhoneNumber(value).nationalNumber);
      document.querySelector(".Proceed").removeAttribute("disabled");
    } else {
      document.querySelector(".Proceed").setAttribute("disabled", true);
      setphoneNumber(0);
    }
  });

  function signup(phone, email, name) {
    var config = {
      method: "post",
      url: saveUrl + "auth/register",
      data: {
        phone: phone,
        email: email,
        name: name,
        cr_number: crNumber,
      },
    };
    axios(config)
      .then(function (response) {
        if (response.data.status) {
          setIsValid(true);
          sessionStorage.setItem(
            "user",
            JSON.stringify(response.data.data.user)
          );

          setIsValid(true);
        }
      })
      .catch(function (error) {
        toast(error.response.data.message);

      });
  }
  return (
    <div className="phone-number-wrapper">
      {isvalid ? <Navigate to="/checkout" replace={true} /> : ""}
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="custom-box-shadow phone-number-container text-center">
              <img alt="logo" src="./images/Logo.svg" />
              <p className="enter-number">Enter Your Phone Number</p>
              <form>
                <input
                  type="text"
                  onChange={(e) => setcrNumber(e.target.value)}
                  className="cr_number"
                  placeholder="Commercial Registration Number"
                />
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="name"
                  placeholder="Business Name"
                />
                <PhoneInput
                  placeholder="Enter phone number"
                  displayInitialValueAsLocalNumber
                  defaultCountry="SA"
                  value={value ? value : ""}
                  international={false}
                  countries={["SA", "EG", "AE"]}
                  onChange={setValue}
                />
                <input
                  placeholder="Email Address"
                  onChange={(e) => setemailAddress(e.target.value)}
                  className="email"
                  type="email"
                />
              </form>
              <button
                className="Proceed"
                onClick={() => signup(phoneNumber, emailAddress, name)}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signinform;
