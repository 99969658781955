import "react-phone-number-input/style.css";
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import "../assets/scss/components/phone-number.scss";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';

sessionStorage.removeItem("access_token");
function Phonenumber() {
  const [value, setValue] = useState("");
  const [countryCode, setCountryCode] = useState();
  const [phoneNumber, setphoneNumber] = useState();
  const [isvalid, setIsValid] = useState(false);

  function signin(countryCode, phoneNumber) {
    let saveUrl = process.env.REACT_APP_API_URL;
    var config = {
      method: "post",
      url: saveUrl + "auth/login",
    
      data: {
        country_code: countryCode,
        phone: phoneNumber,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.status) {
          sessionStorage.setItem("status", "true");
          sessionStorage.setItem("phone", phoneNumber);
          sessionStorage.setItem("country_code", countryCode);

          setIsValid(true);
        }
      })
      .catch(function (error) {    toast(error.response.data.message);});
  }
  useEffect(() => {
    if (value && isValidPhoneNumber(value)) {
      setCountryCode(parsePhoneNumber(value).countryCallingCode);
      setphoneNumber(parsePhoneNumber(value).nationalNumber);
      document.querySelector(".Proceed").removeAttribute("disabled");
    } else {
      document.querySelector(".Proceed").setAttribute("disabled", true);
      setCountryCode(0);
      setphoneNumber(0);
    }
  });

  return (
    <div className="phone-number-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="custom-box-shadow phone-number-container text-center">
              <img alt="logo" src="./images/Logo.svg" />
              <p className="enter-number">Enter Your Phone Number</p>
              <PhoneInput
                placeholder="Enter phone number"
                displayInitialValueAsLocalNumber
                defaultCountry="SA"
                value={value ? value : ""}
                international={false}
                countries={["SA", "EG", "AE"]}
                onChange={setValue}
              />
              {isvalid ? <Navigate to="/otp" replace={true} /> : ""}
              <button
                onClick={function () {
                  signin(countryCode, phoneNumber);
                }}
                className="Proceed"
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Phonenumber;
