import Phonenumber from "../../components/phonenumber";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import Bigloader from "../../components/big-loader";
import MsgFail from "../../components/MsgFail";
function SignIn(params) {
  const [ApiDate, setApiDate] = useState(0);
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [msgFail, setMsgFail] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    let saveUrl = process.env.REACT_APP_API_URL;
    let id = urlParams.get("cart");
    let cartId = "get-cart/" + id;
    sessionStorage.setItem("id", urlParams.get("cart"));
    var config = {
      method: "get",
      url: saveUrl + cartId,
    };

    axios(config)
      .then(function (response) {
        if (typeof window !== "undefined") {
          if (!id) {
            window.location.replace("http://qashi.io/");
          }
        }

        setApiDate(response.data.data);

        sessionStorage.setItem("getCart", JSON.stringify(response.data.data));
        sessionStorage.setItem(
          "merchant",
          JSON.stringify(response.data.data.cart.merchant)
        );
        sessionStorage.setItem(
          "payment_method",
          JSON.stringify(response.data.data.payment_method)
        );
        setIsLoading(false);
      })
      .catch(function (error) {
        
        setIsLoading(false);
        setMsgFail(true);
        if (typeof window !== "undefined") {
          if (!id) {
            window.location.replace("http://qashi.io/");
          }
        }
      });
  }, []);
  return (
    <>
      {msgFail? ( <MsgFail title="Session Expired" description="Your session is expired please try again"/> ) : ""}

      {isLoading ? ( <Bigloader /> ) : (
        <>
          <Phonenumber />
        </>
      )}
    </>
  );
}

export default SignIn;
