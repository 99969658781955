import React from "react";

function Header() {
  return (
      <header className="menu">
        <div className="menu-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <img alt="logo" src="./images/Logo.svg" className="img-fluid" />
              </div>
              <div className="col-6 text-end">
                {/* <a href="#">
                  <img
                    alt="menu-icon"
                    src="./images/menu_icon.svg"
                    className="text-end img-fluid"
                  />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}
export default Header;
