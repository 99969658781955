import { Routes, Route } from "react-router-dom";
import Signinform from "./components/signin-form";
import Checkout from "./pages/checkout/checkout";
import Fail from "./pages/fail";
import App from "./pages/homepage/App";
import Loading from "./pages/loading";
import Otp from "./pages/otp";
import SignIn from "./pages/sign-in";
import Success from "./pages/success";
function RouterOutlet() {
  return (
    <Routes>
      <Route path="cart" element={<App />} />
      <Route path="/" element={<SignIn />} />
      <Route path="otp" element={<Otp />} />
      <Route path="checkout" element={<Checkout />}/>
      <Route path="signinform" element={<Signinform />}/>
      <Route path="loading" element={<Loading />}/>
      <Route path="success" element={<Success />}/>
      <Route path="fail" element={<Fail />}/>
    </Routes>
  );
}

export default RouterOutlet;
