import "../assets/scss/components/msg.scss";

function MsgSuccess() {
  return (
    <>
      <div className="big-loader">
        <div className="phone-number-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="custom-box-shadow phone-number-container text-center">
                <img alt="logo" src="./images/success.svg" />
                  <p className="msgtitle">Your payment on invoice was <br/>success</p>
                  <p>You'll redirect back after seconds</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MsgSuccess;
