import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductDetail from "../components/product-detail/product-details";
import Slidersection from "../components/slider";
import Bigloader from "./big-loader";

function GetCart(props) {
  const [ApiDate, setApiDate] = useState(0);
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    let saveUrl = process.env.REACT_APP_API_URL;
    let id = urlParams.get("cart");
    let cartId = "get-cart/" + id;
    sessionStorage.setItem("id", urlParams.get("cart"));
    var config = {
      method: "get",
      url: saveUrl + cartId,
    
    };

    axios(config)
      .then(function (response) {
        if (typeof window !== "undefined") {
          if (!id) {
            window.location.replace("http://qashi.io/");
          }
        }
        setApiDate(response.data.data);

        sessionStorage.setItem("getCart", JSON.stringify(response.data.data));
        sessionStorage.setItem(
          "merchant",
          JSON.stringify(response.data.data.cart.merchant)
        );
        console.log(response.data.data.payment_method);
        sessionStorage.setItem(
          "payment_method",
          JSON.stringify(response.data.data.payment_method)
        );
        setIsLoading(false);
      })
      .catch(function (error) {
        if (typeof window !== "undefined") {
          if (!id) {
            window.location.replace("http://qashi.io/");
          }
        }
      });
  }, []);
  return (
    <>
      {isLoading ? (
        <Bigloader />
      ) : (
        <>
          <ProductDetail props={ApiDate} />
          <div className="col-lg-5 col-12 offset-lg-1">
            <div className="slick-wrapper">
              <Slidersection props={ApiDate} />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default GetCart;
