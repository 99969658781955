import "../assets/scss/components/msg.scss";
function Failmsg() {
  return (
    <div className="phone-number-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="custom-box-shadow phone-number-container text-center">
              <img alt="logo" src="./images/fail.svg" />
              <p className="msgtitle">No Enough Credit Limit</p>
              <p className="msgnote">
                Settle due payments to resume QashiFlow service
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Failmsg;
