import React from "react";
import { Link } from "react-router-dom";
import "../../assets/scss/elements/buynow.scss"

function Buynow() {
  return (
    <>
     <Link to="/sign-in" className="buy-now d-flex justify-content-between align-items-center">
            Buy Now <img alt="right_arrow" src="./images/right-arrow.png" />
          </Link>
    </>
  );
}
export default Buynow;
