import axios from "axios";
export function getCartapi(countryCode, phoneNumber) {
  let saveUrl = process.env.REACT_APP_API_URL;
  var config = {
    method: "post",
    url: saveUrl + "auth/login",

    data: {
      country_code: countryCode,
      phone: phoneNumber,
    },
  };

  axios(config)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {});
}

